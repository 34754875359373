// https://github.com/excid3/tailwindcss-stimulus-components
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['actionButtons', 'checkboxAll', 'checkboxAllLabel', 'checkbox']

  change (event) {
    event.preventDefault()
    const buttonDiv = this.actionButtonsTarget
    const selectingAll = this.checkboxAllTarget.checked

    if (selectingAll === false) {
      buttonDiv.classList.add('hidden')
      this.checkboxAllLabelTarget.textContent = 'Select all'
      this.checkboxTargets.forEach((checkbox, _index) => {
        checkbox.checked = false
      })
    } else if (selectingAll === true) {
      buttonDiv.classList.remove('hidden')
      this.checkboxAllLabelTarget.textContent = 'Deselect all'
      this.checkboxTargets.forEach((checkbox, _index) => {
        checkbox.checked = true
      })
    } else {
      console.log('oh no')
    }
  }

  singleSelect (event) {
    event.preventDefault()
    const buttonDiv = this.actionButtonsTarget
    const checkedOne = Array.prototype.slice.call(this.checkboxTargets).some(x => x.checked)

    if (checkedOne) {
      buttonDiv.classList.remove('hidden')
    } else {
      buttonDiv.classList.add('hidden')
    }
  }
}
