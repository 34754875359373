import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'
import { useDebounce } from 'stimulus-use'
import mapboxgl from 'mapbox-gl'

export default class extends Controller {
  static debounces = ['save']

  static outlets = ['map']

  static targets = ['link', 'note']

  static values = {
    bookmarks: Array,
    userId: String
  }

  initialize () {
    useDebounce(this, { wait: 800 })
    this.markerCollection = []
  }

  connect () {
    if (document.querySelectorAll('[data-controller="mapless"]').length === 1) {
      this.linkTarget.classList.add('hidden')
    } else if (this.hasLinkTarget) {
      this.linkTarget.classList.remove('hidden')
    }
  }

  disconnect () {
    this.markerCollection.forEach((item, _index) => { item.remove() })
    this.markerCollection = []
    this.bookmarksValue = []
  }

  mapOutletConnected (outlet, _element) {
    if ((typeof outlet.map === 'undefined') || (document.querySelectorAll('[data-controller="mapless"]').length === 1)) {
      return
    }

    if (outlet.currentlyDrawingValue) {
      this.linkTarget.classList.add('hidden')
    }
  }

  place (event) {
    event.preventDefault()

    const timeNow = Date.now()
    const popupContents = `
    <div class="space-y-4">
      <div>
        <div class="text-xs font-normal leading-tight">
          <input type="hidden" autofocus="autofocus" />
          <input id="user-${this.userIdValue}-marker-${timeNow}" type="text" autocomplete="off" placeholder="optional note" class="w-full px-3 py-2 my-2 leading-tight border rounded-lg shadow appearance-none text-zinc-700 border-zinc-300 dark:text-zinc-300 dark:border-zinc-700 dark:bg-zinc-900 hover:border-zinc-500 focus:border-purple-900 focus:outline-none focus:ring-1 focus:ring-purple-500">
        </div>
        <ul role="list" class="space-y-1">
          <li class="relative flex">
            <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
              <div class="w-px bg-gray-200"></div>
            </div>
            <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
            </div>
            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
              <span class="font-medium text-gray-900">Drag</span> the marker to your spot
            </p>
          </li>
          <li class="relative flex">
            <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
              <div class="w-px bg-gray-200"></div>
            </div>
            <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
            </div>
            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
              <span class="font-medium text-gray-900">Tap</span> the map to hide this popup
            </p>
          </li>
          <li class="relative flex">
            <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
              <div class="w-px bg-gray-200"></div>
            </div>
            <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
            </div>
            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
              <span class="font-medium text-gray-900">Tap</span> the marker to show this popup
            </p>
          </li>
          <li class="relative flex">
            <div class="absolute left-0 top-0 flex h-6 w-6 justify-center">
              <div class="w-px bg-gray-200"></div>
            </div>
            <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
            </div>
            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
              <span class="font-medium text-gray-900">Everything</span> automatically saves
            </p>
          </li>
        </ul>
      </div>
    </div>
    `

    const popup = new mapboxgl.Popup({ closeButton: false }).setHTML(popupContents)
    const marker = new mapboxgl.Marker({ draggable: true }).setLngLat(this.mapOutlet.map.getCenter()).setPopup(popup).addTo(this.mapOutlet.map)
    marker.togglePopup()
    marker.on('dragstart', this.dragColor)
    marker.on('dragend', this.save)
    document.getElementById(`user-${this.userIdValue}-marker-${timeNow}`).addEventListener('input', this.inputColor)
    document.getElementById(`user-${this.userIdValue}-marker-${timeNow}`).addEventListener('input', this.save)
    this.markerCollection.push(marker)
  }

  dragColor = (event) => {
    this.setMarkerColor(event.target, 'red')
  }

  inputColor = (event) => {
    event.target.classList.remove('border-zinc-300', 'dark:border-zinc-700', 'hover:border-zinc-500', 'focus:border-purple-900', 'focus:ring-purple-500')
    event.target.classList.add('border-red-300', 'dark:border-red-700', 'hover:border-red-500', 'focus:ring-red-500')
  }

  setMarkerColor (marker, color) {
    const element = marker.getElement()
    const svg = element.getElementsByTagName('svg')[0]
    const path = svg.getElementsByTagName('path')[0]
    path.setAttribute('fill', color)
  }

  save = (event) => {
    const target = event.target
    const interim = this.bookmarksValue
    let latitude, longitude, note, popupId, thisBookmark

    if (target.constructor.name === 'HTMLInputElement') {
      // HTMLInputElement being submitted
      popupId = target.id
      note = target.value
      thisBookmark = interim.find((element) => element.divId === popupId)

      if (thisBookmark) {
        latitude = thisBookmark.latitude
        longitude = thisBookmark.longitude
      } else {
        const mapCenter = this.mapOutlet.map.getCenter()
        latitude = mapCenter.lat
        longitude = mapCenter.lng
      }
    } else {
      // Marker being dragged
      [longitude, latitude] = target.getLngLat().toArray()
      const popup = target.getPopup()
      if (popup.isOpen()) {
        popupId = popup.getElement().getElementsByTagName('input')[1].id
      } else {
        target.togglePopup()
        popupId = popup.getElement().getElementsByTagName('input')[1].id
        target.togglePopup()
      }
      thisBookmark = interim.find((element) => element.divId === popupId)

      if (thisBookmark) {
        popupId = thisBookmark.divId
        note = thisBookmark.note
      }
    }

    const method = thisBookmark ? 'put' : 'post'
    const path = thisBookmark ? `/users/${this.userIdValue}/bookmarks/${thisBookmark.id}.json` : `/users/${this.userIdValue}/bookmarks.json`
    const request = new FetchRequest(method, path, {
      contentType: 'application/json',
      body: JSON.stringify({ bookmark: { popupId, latitude, longitude, note, path: window.location.pathname } }),
      responseKind: 'json'
    })
    request.perform().then(response => {
      return response.json
    }).then(data => {
      if (thisBookmark) {
        const foundIndex = interim.findIndex(element => element.divId === popupId)
        interim[foundIndex] = { id: data.id, divId: popupId, latitude, longitude, note }
      } else {
        interim.push({ id: data.id, divId: popupId, latitude, longitude, note })
      }

      if (target.constructor.name === 'HTMLInputElement') {
        target.classList.remove('border-red-300', 'dark:border-red-700', 'hover:border-red-500', 'focus:ring-red-500')
        target.classList.add('border-zinc-300', 'dark:border-zinc-700', 'hover:border-zinc-500', 'focus:border-purple-900', 'focus:ring-purple-500')
      } else {
        this.setMarkerColor(target, 'green')
      }

      this.bookmarksValue = interim
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  delete (event) {
    event.preventDefault()

    const request = new FetchRequest('delete', `/users/${this.userIdValue}/bookmarks/${event.target.dataset.id}.json`)
    request.perform()
  }
}
