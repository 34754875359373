// Button to toggle full screen mode
class FullScreenControl {
  constructor (supporter) {
    this._supporter = supporter
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=FullScreenControl')
    this._button.setAttribute('title', 'Toggle Fullscreen')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" /></svg>'
    this._container.appendChild(this._button)
    const theMap = document.getElementById('map')

    this._button.addEventListener('debounced:click', () => {
      if (document.location.pathname.endsWith('/map')) {
        return
      }

      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/lifemap-advanced')
      } else if (theMap.classList.contains('map-life')) {
        theMap.classList.add('map-regular')
        theMap.classList.remove('map-life')
        this._map.resize()
        this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" /></svg>'
      } else {
        theMap.classList.remove('map-regular')
        theMap.classList.add('map-life')
        this._map.resize()
        this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" /></svg>'
      }
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default FullScreenControl
