// Button to filter LifeMap
class TimeFilterControl {
  constructor (supporter, userId, viewingSelf) {
    this._supporter = supporter
    this._userId = userId
    this._viewingSelf = viewingSelf
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=TimeFilterControl')
    this._button.setAttribute('title', 'Time Filter')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-purple-900 w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" /></svg>'
    this._container.appendChild(this._button)
    const layerName = this._viewingSelf === true ? 'CityStrides-selfLifeMap' : `CityStrides-lifeMap${this._userId}`

    let datePickerDiv = document.getElementById('time-filter')
    if (!datePickerDiv) {
      datePickerDiv = document.createElement('div')
      datePickerDiv.setAttribute('id', 'time-filter')
      datePickerDiv.className = 'fixed z-40 hidden w-auto p-2 mt-12 ml-12 mr-12 rounded-lg shadow-lg bg-zinc-100 dark:bg-zinc-900'
      const datePickerContent = `
      <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="start" class="block text-sm font-medium text-zinc-700 dark:text-zinc-300">Start</label>
          <div class="mt-1">
            <input type="date" name="start" id="start" placeholder="YYYY-MM-DD" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-zinc-300 dark:border-zinc-700 dark:bg-zinc-900 rounded-md">
          </div>
        </div>
        <div class="sm:col-span-3">
          <label for="end" class="block text-sm font-medium text-zinc-700 dark:text-zinc-300">End</label>
          <div class="mt-1">
            <input type="date" name="end" id="end" placeholder="YYYY-MM-DD" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-zinc-300 dark:border-zinc-700 dark:bg-zinc-900 rounded-md">
          </div>
        </div>
      </div>
      <div class="mt-4">
        <button id="reset" type="button" class="button-red">
          Reset
        </button>
      </div>
      `
      datePickerDiv.innerHTML = datePickerContent
      document.getElementById('mapContainer').appendChild(datePickerDiv)
    }

    this._button.addEventListener('debounced:click', () => {
      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/lifemap-advanced')
      } else if (datePickerDiv.classList.contains('hidden')) {
        if (document.location.pathname.endsWith('/map')) {
          datePickerDiv.classList.remove('mt-2')
          datePickerDiv.classList.add('mt-12')
        } else {
          datePickerDiv.classList.remove('mt-12')
          datePickerDiv.classList.add('mt-2')
        }

        datePickerDiv.classList.remove('hidden')
      } else {
        datePickerDiv.classList.add('hidden')
      }
    }, { passive: true })

    document.getElementById('start').oninput = function () {
      if (!datePickerDiv.classList.contains('hidden')) {
        const endValue = document.getElementById('end').value
        const startValue = this.value
        let selectedEnd
        let selectedStart

        if (startValue === '') {
          selectedStart = Date.parse('0001-01-01T00:00:00')
        } else {
          selectedStart = Date.parse(`${startValue}T00:00:00`)
        }

        if (endValue === '') {
          selectedEnd = Date.parse(`${new Date().getFullYear()}-12-31T23:59:59`)
        } else {
          selectedEnd = Date.parse(`${endValue}T23:59:59`)
        }

        map.setFilter(layerName, ['all', ['>=', 'start_time', selectedStart], ['<=', 'start_time', selectedEnd]])
      }
    }

    document.getElementById('end').oninput = function () {
      if (!datePickerDiv.classList.contains('hidden')) {
        const endValue = this.value
        const startValue = document.getElementById('start').value
        let selectedEnd
        let selectedStart

        if (startValue === '') {
          selectedStart = Date.parse('0001-01-01T00:00:00')
        } else {
          selectedStart = Date.parse(`${startValue}T00:00:00`)
        }

        if (endValue === '') {
          selectedEnd = Date.parse(`${new Date().getFullYear()}-12-31T23:59:59`)
        } else {
          selectedEnd = Date.parse(`${endValue}T23:59:59`)
        }

        map.setFilter(layerName, ['all', ['>=', 'start_time', selectedStart], ['<=', 'start_time', selectedEnd]])
      }
    }

    document.getElementById('reset').addEventListener('click', () => {
      document.getElementById('start').value = null
      document.getElementById('end').value = null
      map.setFilter(layerName, null)
    }, { passive: true })

    return this._container
  }

  onRemove () {
    document.addEventListener('turbo:load', () => {
      const datePickerDiv = document.getElementById('time-filter')
      if (datePickerDiv) {
        datePickerDiv.remove()
      }
      this._container.parentNode.removeChild(this._container)
      this._map = undefined
    }, { once: true })
  }
}

export default TimeFilterControl
