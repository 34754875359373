import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    document.addEventListener('turbo:load', () => {
      document.getElementById('map').classList.add('hidden')
      document.getElementById('routeBuilder').classList.add('hidden')
    }, { once: true })
  }
}
