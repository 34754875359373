import consumer from './consumer'

consumer.subscriptions.create({ channel: 'Noticed::NotificationChannel' }, {
  connected () {
    // Called when the subscription is ready for use on the server
  },

  disconnected () {
    // Called when the subscription has been terminated by the server
  },

  received (data) {
    // Called when there's incoming data on the websocket for this channel
    const messageType = data.message.startsWith('Message from Jim!') ? 'important' : 'info'
    const event = new window.CustomEvent('notifications:toast', { detail: { content: data.message, type: messageType } })
    document.dispatchEvent(event)
  }
})
