import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'
import mapboxgl from 'mapbox-gl'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    leaderboardMarkersPath: String
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    this.popups = []
    outlet.adjustClassesRegular()
    outlet.map.jumpTo({ center: [29, 29], zoom: 0, preload: true })

    if (outlet.mapIsInitialized) {
      this.addLeaders()
    } else {
      outlet.map.once('style.load', () => {
        this.addLeaders()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    outlet.map.removeLayer('CityStrides-leaderCollection-count')
    outlet.removeLayerAndSource('CityStrides-leaderCollection')

    outlet.map.off('click', 'CityStrides-leaderCollection', this.clickLeader)
    outlet.map.off('mousemove', 'CityStrides-leaderCollection', this.hoverLeader)
    outlet.map.off('mouseleave', 'CityStrides-leaderCollection', this.unHoverLeader)

    if (this.popups.length > 0) {
      this.popups.forEach((popup) => {
        popup.remove()
      })
      this.popups = []
    }
  }

  addLeaders () {
    this.mapOutlet.map.resize()
    const request = new FetchRequest('get', this.leaderboardMarkersPathValue)
    request.perform().then(response => {
      return response.json
    }).then(data => {
      this.mapOutlet.map.addSource('CityStrides-leaderCollection', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: data
        },
        cluster: true,
        clusterRadius: 20
      })
      this.mapOutlet.map.addLayer({
        id: 'CityStrides-leaderCollection',
        type: 'circle',
        source: 'CityStrides-leaderCollection',
        paint: {
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 1,
          'circle-color': [
            'match',
            ['get', 'custom-color'],
            'purple',
            '#4c1d95',
            'red',
            '#ef4444',
            /* other */ '#3b82f6'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            100,
            15,
            1000,
            20
          ]
        }
      })
      this.mapOutlet.map.addLayer({
        id: 'CityStrides-leaderCollection-count',
        type: 'symbol',
        source: 'CityStrides-leaderCollection',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-size': 14
        },
        paint: {
          'text-color': 'white'
        }
      })
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })

    this.mapOutlet.map.on('click', 'CityStrides-leaderCollection', this.clickLeader)
    this.mapOutlet.map.on('mousemove', 'CityStrides-leaderCollection', this.hoverLeader)
    this.mapOutlet.map.on('mouseleave', 'CityStrides-leaderCollection', this.unHoverLeader)
  }

  clickLeader = (e) => {
    const features = this.mapOutlet.map.queryRenderedFeatures(e.point, { layers: ['CityStrides-leaderCollection'] })
    const clusterId = features[0].properties.cluster_id

    if (clusterId) {
      this.mapOutlet.map.getSource('CityStrides-leaderCollection').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return

          this.mapOutlet.map.flyTo({
            center: features[0].geometry.coordinates,
            zoom,
            preload: true
          })
        }
      )
    } else {
      this.popups.push(new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(e.features[0].properties.description).addTo(this.mapOutlet.map))
    }
  }

  hoverLeader () {
    this.getCanvas().style.cursor = 'pointer'
  }

  unHoverLeader () {
    this.getCanvas().style.cursor = 'grab'
  }
}
