// https://github.com/excid3/tailwindcss-stimulus-components
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['active', 'inactive']

  static targets = ['tab', 'panel']

  static values = {
    index: { type: Number, default: 0 }
  }

  initialize () {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]

      if (tab.classList.contains(this.activeClass)) {
        tab.classList.add(this.activeClass)
        tab.classList.remove(this.inactiveClass)
        panel.classList.remove('hidden')
      }
    })

    this.changeToAnchorTab()
  }

  change (event) {
    event.preventDefault()
    this.indexValue = this.tabTargets.indexOf(event.currentTarget)
    this.showTab()
  }

  changeToAnchorTab () {
    const anchorValue = window.location.hash.substring(1)

    if (typeof anchorValue === 'string' && anchorValue !== '') {
      this.indexValue = this.panelTargets.findIndex(target => target.id === anchorValue)
      this.showTab()
    }
  }

  showTab () {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      panel.classList.toggle('hidden', index !== this.indexValue)

      if (index === this.indexValue) {
        tab.classList.add(this.activeClass)
        tab.classList.remove(this.inactiveClass)
      } else {
        tab.classList.add(this.inactiveClass)
        tab.classList.remove(this.activeClass)
      }
    })
  }
}
