import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  unMark (_event) {
    this.dispatch('toast', {
      prefix: 'notifications',
      detail: { content: '👍 This street is scheduled to be unmarked. It may take a few minutes to complete.', type: 'success' }
    })
  }
}
