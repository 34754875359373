// https://github.com/excid3/tailwindcss-stimulus-components
import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['deleteAll', 'readAll', 'readSelected', 'deleteSelected', 'item', 'unReadSelected']

  deleteAllItems (event) {
    event.preventDefault()

    const request = new FetchRequest('post', '/notifications-destroy-all.json')
    request.perform().then(_response => {
      window.Turbo.visit('/notifications')
    })
  }

  deleteSelectedItems (event) {
    event.preventDefault()
    const selectedIds = []

    this.itemTargets.forEach((item, _index) => {
      if (item.querySelector("input[type='checkbox']").checked) {
        selectedIds.push(item.id.split('notification_')[1])
      }
    })

    const request = new FetchRequest('post', '/notifications-destroy-selected.json', {
      body: JSON.stringify({ selected_ids: selectedIds })
    })
    request.perform().then(_response => {
      window.Turbo.visit('/notifications')
    })
  }

  readSelectedItems (event) {
    event.preventDefault()
    const selectedIds = []

    this.itemTargets.forEach((item, _index) => {
      if (item.querySelector("input[type='checkbox']").checked) {
        selectedIds.push(item.id.split('notification_')[1])
      }
    })

    const request = new FetchRequest('post', '/notifications-read-selected.json', {
      body: JSON.stringify({ selected_ids: selectedIds })
    })
    request.perform().then(_response => {
      window.Turbo.visit('/notifications')
    })
  }

  readAllItems (event) {
    event.preventDefault()

    const request = new FetchRequest('post', '/notifications-read-all.json')
    request.perform().then(_response => {
      window.Turbo.visit('/notifications')
    })
  }

  unreadSelectedItems (event) {
    event.preventDefault()
    const selectedIds = []

    this.itemTargets.forEach((item, _index) => {
      if (item.querySelector("input[type='checkbox']").checked) {
        selectedIds.push(item.id.split('notification_')[1])
      }
    })

    const request = new FetchRequest('post', '/notifications-unread-selected.json', {
      body: JSON.stringify({ selected_ids: selectedIds })
    })
    request.perform().then(_response => {
      window.Turbo.visit('/notifications')
    })
  }
}
