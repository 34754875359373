import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['selected', 'output']
  static values = {
    cities: Array,
    param: String,
    url: String
  }

  connect () {
    if (this.outputTarget.id === '') {
      this.outputTarget.id = Math.random().toString(36)
    }
  }

  change (event) {
    const params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append('target', this.outputTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    })
  }

  selectAll (event) {
    JSON.parse(event.target.dataset.adPlacementCitiesValue).forEach(city => {
      const input = document.createElement('input')
      input.setAttribute('class', 'hidden')
      input.setAttribute('type', 'checkbox')
      input.setAttribute('value', city)
      input.setAttribute('name', 'ad[city_ids][]')
      input.setAttribute('id', `ad_city_ids_${city}`)
      this.selectedTarget.appendChild(input)
      input.checked = true
    })
    this.outputTarget.innerHTML = ''
  }

  select (event) {
    const cityId = event.target.id.replace(/\w+_city_/, '')
    const cityName = event.target.innerHTML.trim()

    if (document.getElementById(`ad_city_ids_${cityId}`)) {
      document.getElementById(`available_city_${cityId}`).classList.add('hidden')
      return
    }

    const label = document.createElement('label')
    label.setAttribute('class', 'hover:cursor-pointer')
    label.setAttribute('for', `ad_city_ids_${cityId}`)
    const input = document.createElement('input')
    input.setAttribute('class', 'mr-2 h-4 w-4 rounded border-zinc-300 hover:border-zinc-500 text-purple-900')
    input.setAttribute('type', 'checkbox')
    input.setAttribute('value', cityId)
    input.setAttribute('name', 'ad[city_ids][]')
    input.setAttribute('id', `ad_city_ids_${cityId}`)
    label.appendChild(input)
    label.appendChild(document.createTextNode(cityName))
    this.selectedTarget.appendChild(label)
    input.checked = true
    document.getElementById(`available_city_${cityId}`).classList.add('hidden')
  }
}
