import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  static values = {
    index: { type: Number, default: 0 }
  }

  toggle (event) {
    this.indexValue = this.itemTargets.indexOf(event.currentTarget)

    this.itemTargets.forEach((target, index) => {
      if (index === this.indexValue) {
        target.classList.add('ring')
      } else {
        target.classList.remove('ring')
      }
    })
  }
}
