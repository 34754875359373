import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'prepayAmount',
    'prepayMonths'
  ]

  connect () {
    this.prepayMenuChange()
  }

  prepayMenuChange () {
    const element = this.prepayAmountTarget
    const amount = element.options[element.selectedIndex].innerHTML
    const months = parseInt(amount.replace('$', '')) / 5

    if (isNaN(months)) {
      this.prepayMonthsTarget.innerHTML = null
    } else {
      this.prepayMonthsTarget.innerHTML = `<p class='pt-2'><strong>${months}</strong> month(s) of access to all Supporter features</p>`
    }
  }
}
