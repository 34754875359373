import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='service-worker'
export default class extends Controller {
  initialize () {
    this.boundControllerChange = this.controllerChange.bind(this)
    this.boundStateChange = this.stateChange.bind(this)
  }

  connect () {
    if (navigator.serviceWorker) {
      if (navigator.serviceWorker.controller) {
        // console.log('This page is currently controlled by:', navigator.serviceWorker.controller)
        this.stateChange()
      } else {
        navigator.serviceWorker.register('/service-worker.js', { scope: '/' }).then(_reg => {
          // console.log('[Companion] Service worker registered!')
          // subscribe to Push via registration (_reg) here
        }).catch(error => {
          console.log(`ServiceWorker registration failed: ${error}`)
        })

        navigator.serviceWorker.addEventListener('controllerchange', this.boundControllerChange)
      }
    }
  }

  controllerChange (event) {
    // console.log(`[controllerchange] A 'controllerchange' event has happened within navigator.serviceWorker: ${event}`)
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.addEventListener('statechange', this.boundStateChange)
    }
  }

  stateChange () {
    // const state = navigator.serviceWorker.controller.state
    // console.log(`[controllerchange][statechange] A 'statechange' has occured: ${state}`)
  }
}
