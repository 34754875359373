import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    context: String
  }

  mapOutletConnected (_outlet, _element) {
    if (this.contextValue.startsWith('badge_users') || this.contextValue === 'users') {
      return
    }

    try {
      this.addButton()
    } catch {
      // It's ok to do nothing because the default state of the button is good for the user's first visit
    }
  }

  mapOutletDisconnected (_outlet, _element) {
    // removal is done via map_*_controller.js
  }

  showUser (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const thisTarget = event.currentTarget
    thisTarget.setAttribute('data-action', 'users#hideUser')
    thisTarget.innerHTML = 'Hide'
    const id = thisTarget.getAttribute('data-id')
    const identifier = thisTarget.getAttribute('data-identifier')
    // change localhost to IP for mobile testing
    const tileHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://citystrides.com'
    this.mapOutlet.map.addSource(`CityStrides-userCollection${id}`, {
      type: 'vector',
      maxzoom: 15,
      tiles: [`${tileHost}/users/${id}/tiles/{z}/{x}/{y}`]
    })
    this.mapOutlet.map.addLayer({
      id: `CityStrides-userCollection${id}`,
      type: 'line',
      source: `CityStrides-userCollection${id}`,
      'source-layer': 'activities',
      layout: {
        visibility: 'visible',
        'line-join': 'round',
        'line-cap': 'round',
        'line-sort-key': ['get', 'start_time']
      },
      paint: {
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hide'], false],
          0,
          1
        ],
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          this.stringToColor(identifier),
          this.stringToColor(identifier)
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          4,
          2
        ]
      }
    }, this.mapOutlet.map.getLayer('CityStrides-selfLifeMap') ? 'CityStrides-selfLifeMap' : 'road-label')

    if (this.contextValue.startsWith('challenge_users')) {
      const challengeMapElement = document.getElementById('challenge-map')
      const endAt = challengeMapElement.dataset.mapChallengeChallengeEndAtValue
      const startAt = challengeMapElement.dataset.mapChallengeChallengeStartAtValue

      this.mapOutlet.map.setFilter(
        `CityStrides-userCollection${id}`,
        [
          'all',
          ['>=', 'start_time', Date.parse(`${startAt}T00:00:00`)],
          ['<=', 'start_time', Date.parse(`${endAt}T23:59:59`)]
        ]
      )
    }

    const hideAllUsersButton = document.getElementById('hide-all-users')
    if (hideAllUsersButton) {
      hideAllUsersButton.classList.remove('hidden')
    }
  }

  hideAllUsers (event) {
    if (event) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }

    document.querySelectorAll('.show-hide-user').forEach(function (button) {
      button.setAttribute('data-action', 'users#showUser')
      button.innerHTML = 'Show'
    })
    this.mapOutlet.removeAllUsers()
    const hideAllUsers = document.getElementById('hide-all-users')
    if (hideAllUsers) {
      hideAllUsers.classList.add('hidden')
    }
  }

  hideUser (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    let doNotRemoveButton = false
    const thisTarget = event.currentTarget
    thisTarget.setAttribute('data-action', 'users#showUser')
    thisTarget.innerHTML = 'Show'
    this.mapOutlet.removeLayerAndSource(`CityStrides-userCollection${thisTarget.getAttribute('data-id')}`)

    this.mapOutlet.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-userCollection')) {
        doNotRemoveButton = true
      }
    })
    if (!doNotRemoveButton) {
      const hideAllUsers = document.getElementById('hide-all-users')
      if (hideAllUsers) {
        hideAllUsers.classList.add('hidden')
      }
    }
  }

  addButton () {
    let showButton = false
    this.mapOutlet.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-userCollection')) {
        showButton = true
      }
    })
    if (showButton) {
      const hideAllUsers = document.getElementById('hide-all-users')
      if (hideAllUsers) {
        hideAllUsers.classList.remove('hidden')
      }
    }
  }

  stringToColor = (string) => {
    let hash = 0
    string.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += value.toString(16).padStart(2, '0')
    }
    return color
  }
}
