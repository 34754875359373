import { Controller } from '@hotwired/stimulus'
import { Chart, registerables } from 'chart.js'
import { FetchRequest } from '@rails/request.js'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static debounces = ['fetchAndDisplayData']

  static targets = ['canvas', 'cumulative', 'end', 'group', 'start']

  static values = {
    chartType: { type: String, default: 'line' },
    startDate: String,
    endDate: String,
    cumulative: String,
    group: String,
    userId: String
  }

  initialize () {
    // It's possible to decrease the amount of Chart.js that's being imported
    // https://www.chartjs.org/docs/latest/getting-started/integration.html
    Chart.register(...registerables)
    useDebounce(this)
  }

  connect () {
    const request = new FetchRequest('get', `/users/${this.userIdValue}/chart.json`, {
      contentType: 'application/json',
      query: { start_date: this.startDateValue, end_date: this.endDateValue, group: this.groupValue },
      responseKind: 'json'
    })
    request.perform().then(response => {
      return response.json
    }).then(data => {
      this.chart = new Chart(this.canvasTarget.getContext('2d'), {
        type: this.chartTypeValue,
        data: data.data,
        options: {
          color: '#4c1d95',
          maintainAspectRatio: false,
          scales: {
            y: {
              min: 0
            }
          }
        }
      })
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  disconnect () {
    if (this.chart) {
      this.chart.destroy()
      this.chart = undefined
    }
  }

  fetchAndDisplayData () {
    const request = new FetchRequest('get', `/users/${this.userIdValue}/chart.json`, {
      contentType: 'application/json',
      query: { start_date: this.startDateValue, end_date: this.endDateValue, group: this.groupValue, cumulative: this.cumulativeValue },
      responseKind: 'json'
    })
    request.perform().then(response => {
      return response.json
    }).then(data => {
      this.chart.data = data.data
      this.chart.update()
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  reset (event) {
    event.preventDefault()

    this.cumulativeValue = ''
    this.cumulativeTarget.checked = false
    this.endDateValue = ''
    this.endTarget.value = ''
    this.groupValue = 'month'
    this.groupTarget.value = 'month'
    this.startDateValue = ''
    this.startTarget.value = ''
    this.fetchAndDisplayData()
  }

  // these functions need a debounce
  cumulativeChange (event) {
    this.cumulativeValue = event.target.checked
    this.fetchAndDisplayData()
  }

  endChange (event) {
    this.endDateValue = event.target.value
    this.fetchAndDisplayData()
  }

  groupChange (event) {
    this.groupValue = event.target.value
    this.fetchAndDisplayData()
  }

  startChange (event) {
    this.startDateValue = event.target.value
    this.fetchAndDisplayData()
  }
}
