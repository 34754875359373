// Button to toggle between streets and satellite view
class ToggleStyleControl {
  constructor (mapboxInstance, supporter) {
    this._mapboxInstance = mapboxInstance
    this._supporter = supporter
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=ToggleStyleControl')
    this._button.setAttribute('title', 'Toggle Street/Satellite View')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-purple-900 w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" /></svg>'
    this._container.appendChild(this._button)

    this._button.addEventListener('debounced:click', () => {
      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/lifemap-advanced')
        return
      }

      if (this._mapboxInstance.mapStyleValue === this._mapboxInstance.regularStyleLink) {
        this._mapboxInstance.mapStyleValue = this._mapboxInstance.satelliteStyleLink
      } else {
        this._mapboxInstance.mapStyleValue = this._mapboxInstance.regularStyleLink
      }

      this._mapboxInstance.changeMapStyle()
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default ToggleStyleControl
