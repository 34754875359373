import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    challengeId: String
  }

  mapOutletConnected (_outlet, _element) {
    try {
      this.addButton()
    } catch {
      // It's ok to do nothing because the default state of the button is good for the user's first visit
    }
  }

  mapOutletDisconnected (_outlet, _element) {
    // removal is done via map_*_controller.js
  }

  showStreet (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const thisTarget = event.currentTarget
    thisTarget.setAttribute('data-action', 'streets#hideStreet')
    thisTarget.innerHTML = 'Hide'
    const id = thisTarget.getAttribute('data-id')
    const markerArray = []
    const request = new FetchRequest('get', `/streets/${id}/markers.json?challenge_id=${this.challengeIdValue}`)
    request.perform().then(response => {
      if (response.ok) {
        return response.json
      } else if (response.statusCode === 429) {
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: 'You have made too many requests in a short amount of time', type: 'error' }
        })
        return []
      } else if (response.statusCode === 521) {
        this.dispatch('toast', {
          prefix: 'notifications',
          detail: { content: 'The server is temporarily down, please try again later', type: 'error' }
        })
        return []
      } else {
        throw Error('😱 There was an error when retrieving the nodes')
      }
    }).then(json => {
      for (let i = 0; i < json.length; i++) {
        markerArray.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [json[i][1], json[i][0]]
          },
          properties: {
            'custom-color': json[i][3]
          }
        })
      }

      if (!this.mapOutlet.map.getSource(`CityStrides-markerCollection${id}`)) {
        this.mapOutlet.map.addSource(`CityStrides-markerCollection${id}`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: markerArray
          }
        })
      }

      if (!this.mapOutlet.map.getLayer(`CityStrides-markerCollection${id}`)) {
        this.mapOutlet.map.addLayer({
          id: `CityStrides-markerCollection${id}`,
          type: 'circle',
          source: `CityStrides-markerCollection${id}`,
          paint: {
            'circle-stroke-color': '#fff',
            'circle-stroke-width': 1,
            'circle-color': [
              'match',
              ['get', 'custom-color'],
              'purple',
              '#4c1d95',
              'red',
              '#ef4444',
              /* other */ '#3C83F6'
            ],
            'circle-radius': 8
          }
        }, 'road-label')
      }

      try {
        document.getElementById('hide-all-streets').classList.remove('hidden')
      } catch {
        console.log('hide-all-streets button not found')
      }
    }).catch(error => {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: error, type: 'error' }
      })
      this.application.handleError(error)
    })
  }

  hideAllStreets (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    document.querySelectorAll('.show-hide-street').forEach(function (button) {
      button.setAttribute('data-action', 'streets#showStreet')
      button.innerHTML = 'Show'
    })
    this.mapOutlet.removeAllStreets()
    document.getElementById('hide-all-streets').classList.add('hidden')
  }

  hideStreet (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    let doNotRemoveButton = false
    const thisTarget = event.currentTarget
    thisTarget.setAttribute('data-action', 'streets#showStreet')
    thisTarget.innerHTML = 'Show'
    this.mapOutlet.removeLayerAndSource(`CityStrides-markerCollection${thisTarget.getAttribute('data-id')}`)

    this.mapOutlet.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-markerCollection')) {
        doNotRemoveButton = true
      }
    })
    if (!doNotRemoveButton) {
      document.getElementById('hide-all-streets').classList.add('hidden')
    }
  }

  addButton () {
    let showButton = false
    this.mapOutlet.map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('CityStrides-markerCollection')) {
        showButton = true
      }
    })
    if (showButton) {
      document.getElementById('hide-all-streets').classList.remove('hidden')
    }
  }
}
