import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'filterCity',
    'filterCountry',
    'filterRegion',
    'sortBy'
  ]

  filterCityMenuSelect () {
    const sortElement = this.sortByTarget
    const filterCountryElement = this.filterCountryTarget
    const filterRegionElement = this.filterRegionTarget
    const filterCityElement = this.filterCityTarget

    window.Turbo.visit(
      `/leaderboard?sorting=${sortElement.options[sortElement.selectedIndex].value}&country=${filterCountryElement.options[filterCountryElement.selectedIndex].value}&region=${filterRegionElement.options[filterRegionElement.selectedIndex].value}&city=${filterCityElement.options[filterCityElement.selectedIndex].value}`
    )
  }

  filterCountryMenuSelect () {
    const sortElement = this.sortByTarget
    const filterCountryElement = this.filterCountryTarget

    window.Turbo.visit(
      `/leaderboard?sorting=${sortElement.options[sortElement.selectedIndex].value}&country=${filterCountryElement.options[filterCountryElement.selectedIndex].value}`
    )
  }

  filterRegionMenuSelect () {
    const sortElement = this.sortByTarget
    const filterCountryElement = this.filterCountryTarget
    const filterRegionElement = this.filterRegionTarget

    window.Turbo.visit(
      `/leaderboard?sorting=${sortElement.options[sortElement.selectedIndex].value}&country=${filterCountryElement.options[filterCountryElement.selectedIndex].value}&region=${filterRegionElement.options[filterRegionElement.selectedIndex].value}`
    )
  }

  sortingMenuSelect () {
    const sortElement = this.sortByTarget
    const filterCountryElement = this.filterCountryTarget
    const filterRegionElement = this.filterRegionTarget
    const filterCityElement = this.filterCityTarget

    window.Turbo.visit(
      `/leaderboard?sorting=${sortElement.options[sortElement.selectedIndex].value}&country=${filterCountryElement.options[filterCountryElement.selectedIndex].value}&region=${filterRegionElement.options[filterRegionElement.selectedIndex].value}&city=${filterCityElement.options[filterCityElement.selectedIndex].value}`
    )
  }
}
