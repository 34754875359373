// Button to trigger ReRun

class RerunControl {
  constructor (animationCounter, convertedPolyline, marker, supporter) {
    this._animationCounter = animationCounter
    this._convertedPolyline = convertedPolyline
    this._marker = marker
    this._supporter = supporter
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=RerunControl')
    this._button.setAttribute('title', 'ReRun')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-purple-900 w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" /></svg>'
    this._container.appendChild(this._button)
    const marker = this._marker
    const convertedPolyline = this._convertedPolyline
    let animationCounter = this._animationCounter

    function animateMarker (timestamp) {
      const thisMarker = convertedPolyline[animationCounter]
      if (thisMarker) {
        marker.setLngLat([thisMarker[0], thisMarker[1]])
        marker.addTo(map)
        animationCounter = animationCounter + 1

        if (animationCounter < convertedPolyline.length) {
          window.requestAnimationFrame(animateMarker)
        } else {
          marker.addTo(map).remove()
        }
      }
    }

    this._button.addEventListener('debounced:click', () => {
      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/rerun')
      } else {
        window.requestAnimationFrame(animateMarker)
      }
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default RerunControl
