import { Controller } from '@hotwired/stimulus'
import { Notyf } from 'notyf'

export default class extends Controller {
  static values = {
    alert: String,
    notice: String
  }

  initialize () {
    this.notyf = new Notyf({
      duration: this.durationValue,
      ripple: true,
      types: [
        {
          type: 'success',
          background: 'green',
          dismissible: true,
          duration: 5000,
          icon: { className: 'notyf__icon--success', tagName: 'i' }
        },
        {
          type: 'important',
          background: '#3b82f6',
          dismissible: false,
          duration: 0,
          icon: false
        },
        {
          type: 'info',
          background: '#3b82f6',
          dismissible: true,
          duration: 0,
          icon: false
        },
        {
          type: 'error',
          background: 'red',
          dismissible: true,
          duration: 5000,
          icon: { className: 'notyf__icon--error', tagName: 'i' }
        }
      ]
    })
    this.boundToast = this.toast.bind(this)
  }

  connect () {
    document.addEventListener('notifications:toast', this.boundToast)
  }

  disconnect () {
    document.removeEventListener('notifications:toast', this.boundToast)
  }

  toast ({ detail: { content, type } }) {
    this.notyf.open({ type, message: content })
  }

  hideNodeHunterLimit () {
    document.cookie = 'hideNodeHunterLimit=true; max-age=31536000; path=/'
    this.notyf.dismissAll()
  }

  noticeValueChanged () {
    if (!this.noticeValue || this.noticeValue === '') {
      return
    }

    this.notyf.open({ message: this.noticeValue, type: 'success' })
    this.noticeValue = ''
  }

  alertValueChanged () {
    if (!this.alertValue || this.alertValue === '') {
      return
    }

    this.notyf.open({ message: this.alertValue, type: 'error' })
    this.alertValue = ''
  }
}
