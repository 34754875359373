import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'item']

  toggle (event) {
    event.preventDefault()

    this.itemTargets.forEach(target => {
      target.classList.toggle('hidden')
    })

    this.iconTargets.forEach(target => {
      target.classList.toggle('-rotate-180')
      target.classList.toggle('rotate-0')
    })
  }
}
