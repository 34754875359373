import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  zoom (event) {
    const zoomer = event.currentTarget
    const x = event.offsetX / zoomer.offsetWidth * 100
    const y = event.offsetY / zoomer.offsetHeight * 100
    zoomer.style.backgroundPosition = `${x}% ${y}%`
  }
}
