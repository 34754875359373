// Button to start Route Builder
class RouteBuilderControl {
  constructor (supporter) {
    this._supporter = supporter
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=RouteBuilderControl')
    this._button.setAttribute('title', 'Route Builder')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="text-purple-900 h-6 w-6 pl-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="6" cy="19" r="2" /><circle cx="18" cy="5" r="2" /><path d="M12 19h4.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h3.5" /></svg>'
    this._container.appendChild(this._button)

    this._button.addEventListener('debounced:click', () => {
      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/route-builder')
      } else if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
        if (document.getElementById('mapContainer').getAttribute('data-map-route-geojson-value') === '') {
          document.getElementById('mapContainer').setAttribute('data-map-currently-drawing-value', false)
        } else if (window.confirm('Are you sure?')) {
          document.getElementById('mapContainer').setAttribute('data-map-currently-drawing-value', false)
        }
      } else {
        document.getElementById('mapContainer').setAttribute('data-map-currently-drawing-value', true)
      }
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default RouteBuilderControl
