import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['selector']
  static values = {
    param: String,
    url: String
  }

  change () {
    const params = new URLSearchParams()
    params.append('notification', this.paramValue)
    params.append('checked', this.selectorTarget.checked)
    post(`${this.urlValue}?${params}`)
  }
}
