import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    domId: String
  }

  mapOutletConnected (_outlet, _element) {
    try {
      this.adjustButton()
    } catch {
      // It's ok to do nothing because the default state of the button is good for the user's first visit
    }
  }

  mapOutletDisconnected (_outlet, _element) {
  }

  adjustButton () {
    let baseName
    let newAction
    let hideButton
    const [record, id] = this.domIdValue.split('_')
    if (record === 'city') {
      baseName = 'cityBorder'
      newAction = 'cities#hideCity'
      hideButton = 'hide-all-cities'
    } else if (record === 'user') {
      baseName = 'userCollection'
      newAction = 'users#hideUser'
      hideButton = 'hide-all-users'
    } else {
      baseName = 'markerCollection'
      newAction = 'streets#hideStreet'
      hideButton = 'hide-all-streets'
    }
    const layerName = `CityStrides-${baseName}${id}`

    if (this.mapOutlet.map.getLayer(layerName)) {
      this.element.setAttribute('data-action', newAction)
      this.element.innerHTML = 'Hide'
      document.getElementById(hideButton).classList.remove('hidden')
    }
  }
}
