import { Controller } from '@hotwired/stimulus'
import along from '@turf/along'
import bbox from '@turf/bbox'
import length from '@turf/length'
import mapboxgl from 'mapbox-gl'
import { polylineToGeoJSON } from '@placemarkio/polyline'
import RerunControl from '../src/mapbox/rerun.js'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    encodedPolyline: String,
    showMap: Boolean,
    supporter: Boolean
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    this.mileMarkers = []
    this.convertedPolyline = polylineToGeoJSON(this.encodedPolylineValue)
    outlet.adjustClassesRegular()

    if (this.showMapValue) {
      document.getElementById('map').classList.remove('hidden')
    } else {
      document.getElementById('map').classList.add('hidden')
      document.getElementById('routeBuilder').classList.add('hidden')
    }

    if (outlet.mapIsInitialized) {
      this.addActivity()
      this.addRerunButton()
    } else {
      outlet.map.once('style.load', () => {
        this.addActivity()
        this.addRerunButton()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    this.convertedPolyline = null
    outlet.removeLayerAndSource('CityStrides-activityPolyline')
    outlet.removeAllStreets()

    if (outlet.map.hasControl(this.rerunControl) === true) {
      outlet.map.removeControl(this.rerunControl)
    }

    if (this.startMarker) {
      this.startMarker.remove()
    }

    if (this.endMarker) {
      this.endMarker.remove()
    }

    if (this.mileMarkers.length > 0) {
      this.mileMarkers.forEach((marker) => {
        marker.remove()
      })
      this.mileMarkers = []
    }
  }

  addRerunButton () {
    this.rerunControl = new RerunControl(
      0,
      this.convertedPolyline.coordinates,
      new mapboxgl.Marker({ color: '#4C1D95' }),
      this.supporterValue
    )

    if (this.mapOutlet.map.hasControl(this.rerunControl) === false) {
      this.mapOutlet.map.addControl(this.rerunControl, 'top-left')
    }
  }

  addActivity () {
    this.mapOutlet.map.resize()
    this.mapOutlet.map.addSource('CityStrides-activityPolyline', {
      type: 'geojson',
      lineMetrics: true,
      data: {
        type: 'Feature',
        geometry: this.convertedPolyline
      }
    })
    this.mapOutlet.map.addLayer({
      id: 'CityStrides-activityPolyline',
      type: 'line',
      source: 'CityStrides-activityPolyline',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#60a5fa',
        'line-width': 5
      }
    }, 'road-label')
    this.placeMileMarkers()
    this.placeStartEndMarkers()
    const markerBoundingBox = bbox({ type: 'Feature', geometry: this.convertedPolyline })
    this.mapOutlet.map.fitBounds(markerBoundingBox, { animate: false, preload: true, padding: 40 })
  }

  placeMileMarkers () {
    const units = this.mapOutlet.unitValue === 'metric' ? 'kilometers' : 'miles'
    const distance = length(this.convertedPolyline, { units }).toFixed(2)

    for (let i = 1; i < distance; i++) {
      const mileMarker = along({ type: 'LineString', coordinates: this.convertedPolyline.coordinates }, i, { units })
      const el = document.createElement('div')
      el.className = 'w-6 h-6 font-bold text-center border-2 border-purple-900 rounded-full shadow bg-zinc-100 mileMarker'
      el.innerHTML = i
      this.mileMarkers.push(new mapboxgl.Marker(el).setLngLat(mileMarker.geometry.coordinates).addTo(this.mapOutlet.map))
    }
  }

  placeStartEndMarkers () {
    const firstPoint = this.convertedPolyline.coordinates[0]
    const lastPoint = this.convertedPolyline.coordinates.slice(-1)[0]
    const startMarkerDiv = document.createElement('div')
    const endMarkerDiv = document.createElement('div')

    startMarkerDiv.innerHTML = '<svg class="h-8 w-8 text-white bg-gradient-to-br from-green-500 to-green-800 rounded-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>'
    endMarkerDiv.innerHTML = '<svg class="h-8 w-8 text-white bg-gradient-to-br from-red-500 to-red-900 rounded-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" /></svg>'
    this.endMarker = new mapboxgl.Marker(endMarkerDiv).setLngLat(lastPoint).addTo(this.mapOutlet.map)
    this.startMarker = new mapboxgl.Marker(startMarkerDiv).setLngLat(firstPoint).addTo(this.mapOutlet.map)
  }
}
