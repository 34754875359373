// Button to trigger RerunLifemap

class RerunLifemapControl {
  constructor (supporter, userId, viewingSelf) {
    this._supporter = supporter
    this._userId = userId
    this._viewingSelf = viewingSelf
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=RerunLifemapControl')
    this._button.setAttribute('title', 'LifeMap ReRun')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-purple-900 w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" /></svg>'
    this._container.appendChild(this._button)
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let currentlyRerunning = false
    const layerName = this._viewingSelf === true ? 'CityStrides-selfLifeMap' : `CityStrides-lifeMap${this._userId}`

    this._button.addEventListener('debounced:click', () => {
      if (currentlyRerunning) {
        return
      }

      if (!this._supporter) {
        window.Turbo.visit('/supporter-features/rerun')
        return
      }

      currentlyRerunning = true
      let featuresIndex = 0
      const features = this._map.querySourceFeatures(layerName, { sourceLayer: 'activities' }).sort((a, b) => { return a.properties.start_time - b.properties.start_time })

      if (features === 'undefined' || features.length === 0) {
        currentlyRerunning = false
        return
      }

      features.forEach((current) => {
        this._map.setFeatureState(
          {
            source: layerName,
            sourceLayer: 'activities',
            id: current.id
          },
          { hide: true }
        )
      })

      this._dateDisplay = document.createElement('div')
      this._dateDisplay.setAttribute('id', 'rerun-lifemap')
      this._dateDisplay.classList.add('fixed')
      this._dateDisplay.classList.add('z-40')
      this._dateDisplay.classList.add('w-auto')
      this._dateDisplay.classList.add('ml-12')
      this._dateDisplay.classList.add('mr-12')
      this._dateDisplay.classList.add(
        document.getElementById('map').classList.contains('map-life') ? 'mt-12' : 'mt-2'
      )
      document.getElementById('mapContainer').appendChild(this._dateDisplay)

      this.interval = setInterval(() => {
        this._map.setFeatureState(
          {
            source: layerName,
            sourceLayer: 'activities',
            id: features[featuresIndex].id
          },
          { hide: false }
        )
        const date = new Date(features[featuresIndex].properties.start_time)
        this._dateDisplay.innerHTML = `<div class="text-4xl text-zinc-700 font-extrabold"><div>${monthNames[date.getMonth()]}</div><div>${date.getFullYear()}</div></div>`
        featuresIndex = featuresIndex + 1
        if (features.length <= featuresIndex) {
          this._dateDisplay.parentNode.removeChild(this._dateDisplay)
          clearInterval(this.interval)
          currentlyRerunning = false
        }
      }, 50)
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
    clearInterval(this.interval)

    if (this._dateDisplay != null && this._dateDisplay.parentNode != null) {
      this._dateDisplay.parentNode.removeChild(this._dateDisplay)
    }
  }
}

export default RerunLifemapControl
