import { Application } from '@hotwired/stimulus'
import consumer from '../channels/consumer'
import Honeybadger from '@honeybadger-io/js'

// Configure honeybadger.js
Honeybadger.configure({
  apiKey: 'hbp_1gM2mOzJehAgZeZhYjsQxNjTEluKEu00ng0t',
  enableUncaught: false,
  environment: process.env.NODE_ENV
})
Honeybadger.setContext({
  tags: 'javascript'
})
Honeybadger.beforeNotify(function (notice) {
  if (/Fetch is aborted/.test(notice.message)) {
    return false
  } else if (/The user aborted a request/.test(notice.message)) {
    return false
  } else if (/this.properties is undefined/.test(notice.message)) {
    return false
  } else if (/Failed to initialize WebGL/.test(notice.message)) {
    return false
  }
})

const application = Application.start()
// Configure Stimulus development experience
application.consumer = consumer
application.debug = process.env.NODE_ENV === 'development'
window.Stimulus = application

// Set up error handler
application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  Honeybadger.notify(error)
}

export { application }
