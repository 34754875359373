/* global File DataTransfer */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'image']

  static values = {
    color1: { type: String, default: '#4c1d95' },
    color2: { type: String, default: '#3b82f6' },
    color3: { type: String, default: '#fcd34d' },
    identifier: String
  }

  connect () {
    this.colors = [this.color1Value, this.color2Value, this.color3Value]

    try {
      this.generate()
    } catch {
      console.log('Avatar generation failed')
    }
  }

  generate () {
    const value = this.identifierValue
    const svg = this._generateShape(value)
    this._attach(svg, value)
    this.preview()
  }

  preview () {
    if (this.inputTarget.files.length === 0) return
    if (this.inputTarget.files.length > 1) return

    const file = this.inputTarget.files.item(0)
    const objectURL = URL.createObjectURL(file)
    Object.assign(this.imageTarget, {
      src: objectURL,
      alt: file.name
    })
  }

  _generateShape (value) {
    const data = this._generateData(value)
    let mouthPath
    if (data.isMouthOpen) {
      mouthPath = `<path d='M15 ${19 + data.mouthSpread}c2 1 4 1 6 0' stroke='${data.faceColor}' fill='none' stroke-linecap='round' />`
    } else {
      mouthPath = `<path d='M13,${19 + data.mouthSpread} a1,0.75 0 0,0 10,0' fill='${data.faceColor}' />`
    }

    return `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36' fill='none' role='img' width='36' height='36'><title>${value}</title><mask id='radial-mask' maskUnits='userSpaceOnUse' x='0' y='0' width='36' height='36'><rect width='36' height='36' rx='72' fill='#FFFFFF' /></mask><g mask='url(#radial-mask)'><rect width='36' height='36' fill='${data.backgroundColor}' /><rect x='0' y='0' width='36' height='36' transform='translate(${data.wrapperTranslateX} ${data.wrapperTranslateY}) rotate(${data.wrapperRotate} 18 18) scale(${data.wrapperScale})' fill='${data.wrapperColor}' rx='${data.isCircle ? 36 : 6}' /><g transform='rotate(${data.faceRotate} 18 18)'>${mouthPath}<rect x='${14 - data.eyeSpread}' y='14' width='1.5' height='2' rx='1' stroke='none' fill='${data.faceColor}' /><rect x='${20 + data.eyeSpread}' y='14' width='1.5' height='2' rx='1' stroke='none' fill='${data.faceColor}' /></g></g></svg>`
  }

  _attach (svg) {
    // Create a new File object
    const avatarFile = new File(
      [svg],
      'avatar.svg',
      {
        type: 'image/svg+xml',
        lastModified: new Date()
      }
    )

    // Now let's create a DataTransfer to get a FileList
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(avatarFile)
    this.inputTarget.files = dataTransfer.files
  }

  _generateData (name) {
    const size = 36
    const numFromName = this._hashCode(name)
    const range = this.colors.length
    const wrapperColor = this._getRandomColor(numFromName, this.colors, range)
    const preTranslateX = this._getUnit(numFromName, 10, 1)
    const wrapperTranslateX = preTranslateX < 5 ? preTranslateX + size / 9 : preTranslateX
    const preTranslateY = this._getUnit(numFromName, 10, 2)
    const wrapperTranslateY = preTranslateY < 5 ? preTranslateY + size / 9 : preTranslateY

    const data = {
      size,
      wrapperColor,
      faceColor: this._getContrast(wrapperColor),
      backgroundColor: this._getRandomColor(numFromName + 13, this.colors, range),
      wrapperTranslateX,
      wrapperTranslateY,
      wrapperRotate: this._getUnit(numFromName, 360),
      wrapperScale: 1 + this._getUnit(numFromName, size / 12) / 10,
      isMouthOpen: this._getBoolean(numFromName, 2),
      isCircle: this._getBoolean(numFromName, 1),
      eyeSpread: this._getUnit(numFromName, 5),
      mouthSpread: this._getUnit(numFromName, 3),
      faceRotate: this._getUnit(numFromName, 10, 3),
      faceTranslateX:
        wrapperTranslateX > size / 6 ? wrapperTranslateX / 2 : this._getUnit(numFromName, 8, 1),
      faceTranslateY:
        wrapperTranslateY > size / 6 ? wrapperTranslateY / 2 : this._getUnit(numFromName, 7, 2)
    }

    return data
  }

  _hashCode (name) {
    let hash = 0
    for (let i = 0; i < name.length; i++) {
      const character = name.charCodeAt(i)
      hash = ((hash << 5) - hash) + character
      hash = hash & hash // Convert to 32bit integer
    }
    return Math.abs(hash)
  }

  _getUnit (number, range, index) {
    const value = number % range

    if (index && ((this._getDigit(number, index) % 2) === 0)) {
      return -value
    } else return value
  }

  _getDigit (number, ntn) {
    return Math.floor((number / Math.pow(10, ntn)) % 10)
  }

  _getBoolean (number, ntn) {
    return (!((this._getDigit(number, ntn)) % 2))
  }

  _getRandomColor (number, colors, range) {
    return colors[(number) % range]
  }

  _getContrast (hexcolor) {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1)
    }

    // Convert to RGB value
    const r = parseInt(hexcolor.substr(0, 2), 16)
    const g = parseInt(hexcolor.substr(2, 2), 16)
    const b = parseInt(hexcolor.substr(4, 2), 16)

    // Get YIQ ratio
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

    // Check contrast
    return (yiq >= 128) ? '#000000' : '#FFFFFF'
  };
}
