import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    // These IDs are set by the hidden_field method in the form
    document.getElementById('payment_city_id').value = document.getElementById('poster-preview-city').value
    document.getElementById('payment_orientation').value = document.getElementById('poster-preview-orientation').value
    document.getElementById('payment_price_id').value = document.getElementById('poster-preview-price-id').value
    document.getElementById('payment_show_city_name').value = document.getElementById('poster-preview-show-city-name').value
    document.getElementById('poster-payment-div').classList.remove('hidden')
  }
}
