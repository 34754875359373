import { Controller } from '@hotwired/stimulus'
import bbox from '@turf/bbox'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    markers: Array
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    outlet.adjustClassesRegular()

    if (outlet.mapIsInitialized) {
      this.placeMarkers()
    } else {
      outlet.map.once('style.load', () => {
        this.placeMarkers()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    outlet.removeLayerAndSource('CityStrides-markers')
    outlet.removeAllUsers()
  }

  placeMarkers () {
    this.mapOutlet.map.resize()
    this.mapOutlet.map.addSource('CityStrides-markers', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.markersValue
      }
    })
    this.mapOutlet.map.addLayer({
      id: 'CityStrides-markers',
      type: 'circle',
      source: 'CityStrides-markers',
      paint: {
        'circle-stroke-color': '#fff',
        'circle-stroke-width': 1,
        'circle-color': [
          'match',
          ['get', 'custom-color'],
          'purple',
          '#4c1d95',
          'red',
          '#ef4444',
          /* other */ '#3C83F6'
        ],
        'circle-radius': 8
      }
    }, 'road-label')

    const markerBoundingBox = bbox({ type: 'FeatureCollection', features: this.markersValue })
    this.mapOutlet.map.fitBounds(markerBoundingBox, { animate: false, preload: true, padding: 40 })
  }
}
