import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    anonymous: Boolean
  }

  click () {
    if (this.anonymousValue === true) {
      this.dispatch('toast', {
        prefix: 'notifications',
        detail: { content: 'You must enter a valid email address', type: 'error' }
      })
    }
  }
}
