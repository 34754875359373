import { Controller } from '@hotwired/stimulus'
import mapboxgl from 'mapbox-gl'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    display: Array
  }

  initialize () {
    this.markerCollection = []
  }

  disconnect () {
    this.markerCollection.forEach((item, _index) => { item.remove() })
    this.markerCollection = []
  }

  show (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    this.displayValue.forEach((bookmark, _index) => {
      const popupContents = `
      <div class="space-y-4">
        <div class="text-xs font-normal leading-tight">
          ${bookmark[0]}
        </div>
      </div>
      `
      const popup = new mapboxgl.Popup({ closeButton: false }).setHTML(popupContents)
      const marker = new mapboxgl.Marker({ draggable: false }).setLngLat(bookmark[1]).setPopup(popup).addTo(this.mapOutlet.map)
      marker.togglePopup()
      this.markerCollection.push(marker)
    })

    try {
      document.getElementById('hide-bookmarks').classList.remove('hidden')
      document.getElementById('show-bookmarks').classList.add('hidden')
    } catch {
      console.log('hide-bookmarks button not found')
    }
  }

  hide (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    this.markerCollection.forEach((item, _index) => { item.remove() })
    this.markerCollection = []
    document.getElementById('hide-bookmarks').classList.add('hidden')
    document.getElementById('show-bookmarks').classList.remove('hidden')
  }
}
