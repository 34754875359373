/* global MutationObserver */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    supporter: { type: Boolean, default: false }
  }

  initialize () {
    if (!this.supporterValue) {
      return
    }

    this.observer = new MutationObserver(this.changeMapStyle)
    this.observer.observe(document.documentElement, { attributes: true })
  }

  disconnect () {
    if (!this.supporterValue) {
      return
    }

    this.observer.disconnect()
  }

  changeMapStyle = (mutationList, _observer) => {
    const mapUndefined = typeof this.mapOutlet.map === 'undefined'

    for (const mutation of mutationList) {
      const classMutation = mutation.type === 'attributes' && mutation.attributeName === 'class'
      const darkIsSelected = mutation.target.classList.contains('dark')
      const satelliteInUse = this.mapOutlet.mapStyleValue === this.mapOutlet.satelliteStyleLink

      if (!classMutation || mapUndefined || satelliteInUse) {
        // do nothing
      } else if (darkIsSelected) {
        this.mapOutlet.mapStyleValue = this.mapOutlet.darkStyleLink
        this.mapOutlet.changeMapStyle()
      } else if (!darkIsSelected) {
        this.mapOutlet.mapStyleValue = this.mapOutlet.regularStyleLink
        this.mapOutlet.changeMapStyle()
      }
    }
  }
}
