import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    coordinates: Array
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    outlet.adjustClassesRegular()

    if (outlet.mapIsInitialized) {
      this.addNode()
    } else {
      outlet.map.once('style.load', () => {
        this.addNode()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    outlet.removeLayerAndSource('CityStrides-node')
  }

  addNode () {
    if (!this.hasCoordinatesValue || this.coordinatesValue.length !== 2) {
      this.mapOutlet.map.jumpTo({ center: [29, 29], zoom: 1.1, preload: true })
      return
    }

    this.mapOutlet.map.resize()
    this.mapOutlet.map.addSource('CityStrides-node', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.coordinatesValue
        }
      }
    })
    this.mapOutlet.map.addLayer({
      id: 'CityStrides-node',
      type: 'circle',
      source: 'CityStrides-node',
      paint: {
        'circle-color': '#4c1d95',
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff',
        'circle-radius': 6
      }
    }, 'road-label')

    this.mapOutlet.map.jumpTo({ center: this.coordinatesValue, zoom: 15, preload: true })
  }
}
